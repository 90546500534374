import Types from "@/constant/column-types";
import FilterText from "./filter-text";
import FilterNumber from "./filter-number";
import FilterDate from "./filter-date";

const map = {
  [Types.text]: FilterText,
  [Types.number]: FilterNumber,
  [Types.date]: FilterDate
};

export default type => map[type] || FilterText;
