<template>
  <VirtualSelect
    v-icon="type"
    :data="data"
    :option-render="optionRender"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import VirtualSelect from "@/components/virtual-select";
import { Icon } from "@/constant/column-types";

function initIcon(el, type) {
  if (!el || el.__icon__) return;
  // 腾出 icon 位置
  const selection = el.querySelector(".ant-select-selection");
  if (!selection) return;
  const label = el.querySelector(".ant-select-selection__rendered");
  if (label && type) label.style.marginLeft = "24px";
  const icon = document.createElement("i");
  el.__icon__ = icon;
  el.__label__ = label;
  icon.className = Icon[type];
  Object.assign(icon.style, {
    position: "absolute",
    top: "50%",
    left: "6px",
    transform: "translateY(-50%)",
    color: "rgba(0, 0, 0, 0.65)"
  });
  selection.append(icon);
}

export default {
  name: "select-column",
  components: { VirtualSelect },
  inheritAttrs: false,
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  },
  model: {
    event: "change"
  },
  directives: {
    icon: {
      // 初始化图标
      inserted(el, { value }) {
        initIcon(el, value);
      },
      // 更新图标
      update(el, { value }) {
        initIcon(el, value);
        el.__icon__.className = Icon[value];
        const label = el.__label__;
        if (label) label.style.marginLeft = value ? "24px" : null;
      }
    }
  },
  computed: {
    // ⚠️ 原 antd Select 组件 change 事件会携带 option 参数
    // 这里取巧直接把 option 设为当前字段，实现向上级传递所选字段功能
    data() {
      return (this.columns || []).map(item => {
        const value = item.columnId;
        const label = item.columnName;
        const type = item.columnType;
        const disabled = item.disabled;
        const hidden = !!item.hidden;
        return { value, label, type, disabled, hidden, option: item };
      });
    },
    type() {
      const { value, type } = this.$attrs;
      if (type) return type;
      if (!value) return null;
      const columns = this.columns || [];
      const target = columns.find(item => item.columnId === value);
      return target && target.columnType;
    }
  },
  methods: {
    optionRender(h, item, nodes) {
      const iconClasses = "ndl-margin-right-sm " + Icon[item.type];
      const style = `color: rgba(0, 0, 0, ${item.disabled ? "0.25" : "0.65"})`;
      const icon = <i class={iconClasses} style={style}></i>;
      nodes.unshift(icon);
      return nodes;
    }
  }
};
</script>
