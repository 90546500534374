import ColumnTypes from "@/constant/column-types";
import Types, { ColumnFilterTypes } from "@/constant/filter-types";
import VirtualSelect from "@/components/virtual-select";
import debounce from "lodash/debounce";

const Option = "a-select-option";

function generate(h, filter, context) {
  const operator = filter.operator;
  // 包含、不包含渲染输入框
  if (operator === Types.CONTAIN || operator === Types.NOT_CONTAIN) {
    return (
      <a-input
        value={filter.value}
        onInput={evt => (filter.value = evt.target.value)}
        class="filter-value"
        placeholder="请输入"
      />
    );
  }
  function onDropdownVisibleChange(open) {
    if (!open) return;
    const listener = context.listeners["on-search"];
    if (listener) listener(filter);
  }
  function onSearch(keyword = "") {
    const listener = context.listeners["on-search"];
    if (listener) listener(filter, { keyword });
  }
  // 等于、不等于渲染选择框
  if (operator === Types.EQUAL || operator === Types.NOT_EQUAL) {
    const filterValues = context.props.filterValues || [];
    return (
      <VirtualSelect
        data={filterValues}
        value={filter.values}
        get-popup-container={context.props.getPopupContainer}
        mode="tags"
        max-tag-count={4}
        max-tag-text-length={5}
        allow-clear
        class="filter-value"
        placeholder="请选择"
        onChange={value => (filter.values = value)}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onSearch={debounce(onSearch, 380)}
      />
    );
  }
  // 其余情况渲染占位符
  return <span class="filter-value"></span>;
}

export default {
  functional: true,
  render(h, context) {
    const filter = context.props.filter;
    const options = ColumnFilterTypes[ColumnTypes.text] || [];
    return [
      <a-select
        value={filter.operator}
        get-popup-container={context.props.getPopupContainer}
        class="filter-operator"
        onChange={value => (filter.operator = value)}
      >
        {options.map(props => (
          <Option value={props.value}>{props.label}</Option>
        ))}
      </a-select>,
      generate(h, filter, context)
    ];
  }
};
