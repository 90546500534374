import { Select } from "ant-design-vue";
import VirtualList from "vue-virtual-scroll-list";

/**
 * babel-plugin-import 插件替换变量名有 bug，故用新的变量名代替
 * https://github.com/ant-design/babel-plugin-import/issues/245
 * https://github.com/ant-design/babel-plugin-import/issues/157
 * https://github.com/ant-design/babel-plugin-import/issues/172
 */
const AntdSelectComponent = Select;

const mixin = {
  data() {
    return {
      vcSelect: null
    };
  },
  mounted() {
    this.updateOptionsInfo();
  },
  watch: {
    data() {
      this.updateOptionsInfo();
    },
    // vcSelect 会刷新 __optionsInfo 选项，这里需要在它之后更新
    "vcSelect.__propsSymbol__"() {
      this.$nextTick(this.updateOptionsInfo);
    }
  },
  methods: {
    updateOptionsInfo() {
      const vcSelect = this.$refs.vcSelect;
      if (!vcSelect || !this.data) return;
      this.vcSelect = vcSelect;
      const optionsInfo = this.data.reduce((map, item) => {
        const key = `${typeof item.value}-${item.value}`;
        item.label = item.label || item.value;
        map[key] = item;
        return map;
      }, {});
      vcSelect.$data._optionsInfo = optionsInfo;
    },
    dropdownRender(vnode, props) {
      // 收起时就没必要渲染啦
      if (!props.visible) return this.__cachedVNode__ || vnode;
      const selectedValues = props.value || [];
      const listeners = vnode.componentOptions.listeners;
      const inputValue = (props.inputValue || "").toLowerCase();
      const isMultiple = props.multiple;
      const itemIcon = isMultiple ? props.menuItemSelectedIcon : null;

      // 渲染选项
      const optionRender = this.optionRender;
      const itemRender = {
        functional: true,
        render(h, context) {
          const source = context.props.source || {};
          context.parent.__source__ = source;
          const label = source.label || source.value;
          const labelNode = h("span", { attrs: { title: label } }, label);
          const nodes = [labelNode, itemIcon];
          // 外部提供了渲染器则调用
          if (optionRender) {
            const args = [h, source, nodes, props, context];
            return optionRender.apply(this, args) || nodes;
          }
          return nodes;
        }
      };
      // 直接使用 antd 自身的 dropdown-menu class 就好了，不用自己写样式～
      const classPrefix = "ant-select-dropdown-menu";
      const rootClasses = [
        classPrefix,
        classPrefix + "-vertical",
        classPrefix + "-root"
      ];
      let datasource = this.data || [];
      datasource = datasource.filter(item => !item.hidden);
      const filterKey = this.optionFilterProp || "value";
      if (inputValue) {
        const filterFn = item => {
          if (!item) return false;
          const filterStr = String(item[filterKey]).toLowerCase();
          return filterStr.includes(inputValue);
        };
        datasource = datasource.filter(filterFn);
      }
      // 空选项提示
      if (!datasource.length) return vnode;
      // 判断选项是否需要添加选中的类名
      function addClass(index) {
        const item = datasource[index];
        if (!item) return "";
        const classList = [];
        if (selectedValues.includes(item.value)) {
          classList.push(classPrefix + "-item-selected");
        }
        if (item.disabled) {
          classList.push(classPrefix + "-item-disabled");
        }
        return classList.join(" ");
      }
      // 点击时触发选择事件，必须在 itemRender 中给每个 item 添加 __source__ 选项
      function onClick(evt) {
        let target = evt.target;
        const selector = `.${classPrefix}-item[role="listitem"]`;
        while (target && !target.matches(selector)) {
          target = target.parentNode;
        }
        const vm = target && target.__vue__;
        if (!vm || !vm.__source__) return;
        const propsData = vm.__source__;
        if (propsData.disabled) return;
        const item = { componentOptions: { propsData } };
        if (selectedValues.includes(propsData.value) && isMultiple) {
          listeners.menuDeselect({ item, domEvent: evt });
        } else {
          listeners.menuSelect({ item });
        }
      }
      this.__cachedVNode__ = (
        <VirtualList
          data-key="value"
          class={rootClasses}
          item-class={classPrefix + "-item"}
          item-class-add={addClass}
          data-sources={datasource}
          data-component={itemRender}
          nativeOnClick={onClick}
          nativeOnMousedown={evt => evt.preventDefault()}
        />
      );
      return this.__cachedVNode__;
    }
  }
};

/**
 * 新组件 props
 * 1、新增 data prop
 * 2、新增 optionRender prop
 * 3、移除 dropdownRender prop
 * 4、移除 options prop
 */
const props = {
  ...AntdSelectComponent.props,
  data: Array,
  optionRender: Function
};
delete props.dropdownRender;
delete props.options;

const VirtualSelect = {
  ...AntdSelectComponent,
  props,
  name: "virtual-select",
  mixins: [mixin]
};
/**
 * antd 的 Select 组件后经 Vue.component 安装注册完
 * 会生成 _Ctor 属性，如不删除会导致 VirtualSelect
 * 被识别成 Select 组件
 */
delete VirtualSelect._Ctor;

export default VirtualSelect;
