import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import Filter from "@/model/filter";
import ColumnTypes from "@/constant/column-types";
import FilterTypes, {
  DateTimeShortcuts,
  DateTimeRangeShortcuts,
  DynamicShortcuts,
  DynamicShortcutUnits,
  ColumnFilterTypes
} from "@/constant/filter-types";

const Option = "a-select-option";

const subComponent = {
  date(h, filter, context) {
    return (
      <div class="wrap-row">
        <a-date-picker
          value={filter.value}
          locale={locale}
          get-calendar-container={context.props.getPopupContainer}
          onChange={(date, value) => (filter.value = value)}
          placeholder="请选择日期"
          style="width: 100%"
        />
      </div>
    );
  },
  daterange(h, filter, context) {
    return (
      <div class="wrap-row">
        <a-range-picker
          value={filter.values}
          locale={locale}
          get-calendar-container={context.props.getPopupContainer}
          placeholder={["选择开始日期", "选择结束日期"]}
          onChange={(date, values) => (filter.values = values)}
        />
      </div>
    );
  },
  dynamic(h, filter, context) {
    return (
      <div class="wrap-row">
        <a-input-group compact class="ndl-flex">
          <a-input-number
            value={filter.value}
            onChange={value => (filter.value = value)}
            placeholder="请输入数值"
            class="ndl-flex-item--grow"
          />
          <a-select
            value={filter.dateShortcutUnit}
            get-popup-container={context.props.getPopupContainer}
            onChange={value => (filter.dateShortcutUnit = value)}
            style="width: 80px;"
          >
            {DynamicShortcutUnits.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </a-select>
        </a-input-group>
      </div>
    );
  },
  switch(h, filter, context) {
    switch (filter.dateShortcut) {
      case "date":
        return this.date(h, filter, context);
      case "daterange":
        return this.daterange(h, filter, context);
      case "latest":
      case "past":
      case "future":
        return this.dynamic(h, filter, context);
    }
  }
};

function generate(h, filter, context) {
  const onChange = value => {
    // 修改 shortcut 时重置过滤器，避免无用数据保存
    Filter.reset(filter);
    filter.dateShortcut = value;
  };
  const wrapRow = subComponent.switch(h, filter, context);
  const fixedStyle = wrapRow ? "margin-right: 25px" : null;
  switch (filter.operator) {
    case FilterTypes.EQUAL:
    case FilterTypes.NOT_EQUAL:
      return [
        <a-select
          value={filter.dateShortcut}
          onChange={onChange}
          get-popup-container={context.props.getPopupContainer}
          class="filter-value"
          style={fixedStyle}
        >
          <a-select-opt-group label="固定日期（范围）">
            <Option value="date">指定日期</Option>
            <Option value="daterange">指定日期范围</Option>
          </a-select-opt-group>
          <a-select-opt-group label="动态日期">
            {DateTimeShortcuts.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </a-select-opt-group>
          <a-select-opt-group label="动态日期范围">
            {DateTimeRangeShortcuts.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </a-select-opt-group>
          <a-select-opt-group label="动态计算日期范围">
            {DynamicShortcuts.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </a-select-opt-group>
        </a-select>,
        wrapRow
      ];
    case FilterTypes.LESS_THAN:
    case FilterTypes.LESS_EQUAL:
    case FilterTypes.GREATER_THAN:
    case FilterTypes.GREATER_EQUAL:
      return [
        <a-select
          value={filter.dateShortcut}
          onChange={onChange}
          get-popup-container={context.props.getPopupContainer}
          class="filter-value"
          style={fixedStyle}
        >
          <Option value="date">指定日期</Option>
          {DateTimeShortcuts.map(item => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </a-select>,
        // 指定日期时渲染日期控件
        wrapRow
      ];
    // case FilterTypes.NULL:
    // case FilterTypes.NOT_NULL:
    default:
      return <span class="filter-value"></span>;
  }
}

export default {
  functional: true,
  render(h, context) {
    const filter = context.props.filter;
    // 修改 operator 时重置一下过滤器，因为不同类型的 shortcut 其 value 可能不同
    // 不重置可能存在 props 校验不通过的问题
    const onChange = value => {
      // 修改 shortcut 时重置过滤器，避免无用数据保存
      Filter.reset(filter);
      filter.operator = value;
    };
    // 设置默认的 shortcut
    if (!filter.dateShortcut) filter.dateShortcut = "date";
    const options = ColumnFilterTypes[ColumnTypes.date] || [];
    return [
      <a-select
        value={filter.operator}
        get-popup-container={context.props.getPopupContainer}
        class="filter-operator"
        onChange={onChange}
      >
        {options.map(props => (
          <Option value={props.value}>{props.label}</Option>
        ))}
      </a-select>,
      generate(h, filter, context)
    ];
  }
};
