import ColumnTypes from "@/constant/column-types";
import FilterTypes, { ColumnFilterTypes } from "@/constant/filter-types";
import InputSelect from "../../context-input-select";
import debounce from "lodash/debounce";

const Option = "a-select-option";

function generate(h, filter, context) {
  const filterValues = context.props.filterValues || [];
  function onSearch(keyword = "") {
    keyword = keyword ? keyword : filter.value;
    const listener = context.listeners["on-search"];
    if (listener) listener(filter, { keyword });
  }

  switch (filter.operator) {
    case FilterTypes.EQUAL:
    case FilterTypes.NOT_EQUAL:
      return (
        <InputSelect
          get-popup-container={context.props.getPopupContainer}
          placeholder="请输入数值"
          class="filter-value"
          value={filter.value}
          filter-values={filterValues}
          onChange={value => (filter.value = value)}
          onSearch={debounce(onSearch, 380)}
        />
      );
    case FilterTypes.LESS_THAN:
    case FilterTypes.LESS_EQUAL:
    case FilterTypes.GREATER_THAN:
    case FilterTypes.GREATER_EQUAL:
      return (
        <a-input-number
          value={filter.value}
          onChange={value => (filter.value = value)}
          placeholder="请输入数值"
          class="filter-value"
        />
      );
    case FilterTypes.BETWEEN:
    case FilterTypes.BETWEEN_NOT_LEFT:
    case FilterTypes.BETWEEN_NOT_RIGHT:
    case FilterTypes.BETWEEN_NOT_BOTH:
      return (
        <a-input-group compact class="filter-value ndl-flex" style="width: 0">
          <a-input-number
            value={filter.startValue}
            onChange={value => (filter.startValue = value)}
            placeholder="最小值"
            class="ndl-flex-item--grow"
          />
          <a-input-number
            value={filter.endValue}
            onChange={value => (filter.endValue = value)}
            placeholder="最大值"
            class="ndl-flex-item--grow"
          />
        </a-input-group>
      );
    // case FilterTypes.NULL:
    // case FilterTypes.NOT_NULL:
    default:
      return <span class="filter-value"></span>;
  }
}

export default {
  functional: true,
  render(h, context) {
    const filter = context.props.filter;
    const options = ColumnFilterTypes[ColumnTypes.number] || [];
    return [
      <a-select
        value={filter.operator}
        get-popup-container={context.props.getPopupContainer}
        class="filter-operator"
        onChange={value => (filter.operator = value)}
      >
        {options.map(props => (
          <Option value={props.value}>{props.label}</Option>
        ))}
      </a-select>,
      generate(h, filter, context)
    ];
  }
};
