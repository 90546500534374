export default {
  name: "select-item",
  props: {
    source: {
      type: Object,
      default() {
        return {};
      }
    },
    click: Function
  },
  render() {
    const source = this.source;
    const onClick = () => {
      // 这里使用 setTimeout 规避一下 a-input-number onChange 的调用
      // 如果不使用它，在点击 item 修改 value 后会调用一次 a-input-number 的 onChange
      // 此前如果直接使用过输入框修改过 value ，点击 item 会导致调用 onChange 时，传入的 value 是旧的 value
      setTimeout(() => {
        if (this.click) this.click(source);
      });
    };
    return <div onMousedown={onClick}>{source.label}</div>;
  }
};
