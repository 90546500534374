<template>
  <div class="pane-wrapper">
    <div class="filter-header ndl-text-desc-small">
      <span>筛选条件({{ filter.conditions.length }})</span>
      <a-checkbox
        v-show="filter.conditions.length > 1"
        :checked="filter.type === 'or' ? true : false"
        @input="filter.type = $event ? 'or' : 'and'"
        size="small"
      >
        满足任一条件
      </a-checkbox>
    </div>
    <div class="filter-wrapper" ref="wrapper">
      <div
        v-for="(item, index) in filter.conditions"
        :key="index"
        class="filter-row"
        :class="item.columnType"
      >
        <span :data-index="index" class="filter-index">{{ index + 1 }}</span>
        <SelectColumn
          :value="keyof(item)"
          :columns="computedColumns"
          :type="item.columnType"
          :get-popup-container="() => $el"
          class="filter-column"
          @change="(...params) => onColumnChange(item, ...params)"
        />
        <component
          :is="getFilterComponent(item)"
          :filter="item"
          :get-popup-container="() => $el"
          :filter-values="filterValuePartMap[item.columnId]"
          @on-search="getFilterValues"
        />
        <i class="remove ndl-icon-x" @click="remove(index)"></i>
      </div>
    </div>
    <div class="filter-bottom ndl-margin">
      <a @click="add()"><i class="ndl-icon-plus"></i>添加条件</a>
    </div>
  </div>
</template>

<script>
import Filter, { pickAttr } from "@/model/filter";
import FilterTypes from "@/constant/filter-types";
import SelectColumn from "@/views/datamodel/components/select-column";
import Actions from "@/constant/datasheet-actions";
import workspace from "@/views/datamodel/models/workspace";
import getFilterComponent from "./get-filter-component";

function keyof(column) {
  return column.columnType + ":" + column.columnId;
}

/**
 * 此组件被
 * `src/views/datamodel/components/dashboard/src/atoms/filter-datasheet/filter-editor.js`
 * mix-in，请在修改后测试相关组件功能
 */
export default {
  components: { SelectColumn },
  props: {
    columns: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    const columnIdMap = this.columns.reduce((map, item) => {
      map[keyof(item)] = item;
      map[item.columnId] = item;
      return map;
    }, {});
    const conditions = this.filter.conditions || [];
    // ⚠️ 因部分操作会导致字段类型变化，为实现类型变化前添加的
    // 过滤器不会受其字段类型变化影响，需要做一些特殊处理：
    // 若过滤器选的字段类型变化，需要创建一个新的 hidden 选项
    // hidden: true 的选项不会显示出来，仅用于 Select 组件显示 label
    const hiddenColumns = conditions
      .filter(item => !columnIdMap[keyof(item)])
      .map(item => {
        const relateColumn = columnIdMap[item.columnId];
        if (!relateColumn) return null;
        const columnId = keyof(item);
        const columnName = item.aggType
          ? `${item.aggType}(${relateColumn.columnName})`
          : relateColumn.columnName;
        const columnType = item.columnType;
        return { columnId, columnName, columnType, hidden: true };
      })
      .filter(Boolean);
    return {
      hiddenColumns,
      filterValueMap: {},
      filterValuePartMap: {}
    };
  },
  computed: {
    // 过滤器的字段列表比较特殊，列类型变更后算不同的列
    // 因此用 columnType + columnid 作为列的唯一标识符
    computedColumns() {
      const parsedColumns = this.columns.map(item => {
        const columnName = item.aggType
          ? `${item.aggType}(${item.columnName})`
          : item.columnName;
        const columnType = item.columnType;
        const columnId = keyof(item);
        const originColumn = item;
        return { columnName, columnId, columnType, originColumn };
      });
      return parsedColumns.concat(this.hiddenColumns);
    }
  },
  filters: {
    aggType(columnName, aggType) {
      if (!aggType) return columnName;
      return `${aggType}(${columnName})`;
    }
  },
  methods: {
    keyof,
    async add() {
      const columnMeta = pickAttr(this.columns[0] || {});
      const cond = new Filter(columnMeta);
      this.filter.conditions.push(cond);
      await this.$nextTick();
      const wrapper = this.$refs.wrapper;
      // 自动滚动到底部
      wrapper.scrollTo({
        top: 1000000,
        left: 0,
        behavior: "smooth"
      });
    },
    remove(index) {
      this.filter.conditions.splice(index, 1);
    },
    getFilterComponent(filter) {
      return getFilterComponent(filter.columnType);
    },
    async getFilterValues(filter, options = {}) {
      const map = this.filterValueMap || {};
      const columnId = filter.columnId;
      if (!map[columnId]) {
        const selection = this.filter;
        const tempOptions = { ...options, keyword: null };
        const action = Actions.filterValues(
          {
            ...tempOptions,
            filter,
            selection,
            page: 1,
            // 这里提供 2000 条用户选择应该够了吧，太多选项也是够呛
            size: 2000
          },
          chunk => (chunk.disableLoading = true)
        );
        let { data = [] } = await workspace.currentDatasheet.send(action);
        data = data.map(value => ({ value }));
        map[columnId] = data;
      }

      const list = map[columnId];
      const mapPart = this.filterValuePartMap || {};
      if (options.keyword || options.keyword === 0) {
        this.$set(
          mapPart,
          columnId,
          list.filter(item => item.value.includes(options.keyword))
        );
      } else {
        this.$set(mapPart, columnId, Array.from(list));
      }
      return list;
    },
    // 切换到不同类型的字段时，重置到默认的 operator，避免出现不合法的 operator
    onColumnChange(filter, value, option) {
      const column = option.originColumn || option;
      Filter.reset(filter);
      const columnMeta = pickAttr(column || {});
      Object.assign(filter, columnMeta);
      filter.operator = FilterTypes.EQUAL;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@row-gutter: 8px;
@column-width: 160px;
@index-gutter: 30px;
.pane-wrapper {
  width: 540px + @index-gutter;
  .filter-header {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0 @padding-md;
    .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      transform: scale(0.85);
      transform-origin: right;
      /deep/ .ant-checkbox {
        top: 0;
      }
    }
  }
  .filter-wrapper {
    max-height: 380px;
    padding: 5px @padding-md;
    overflow: auto;
  }
  .filter-row {
    display: flex;
    align-items: center;
    position: relative;
    // 左侧留出 30px 显示序号
    padding-left: @index-gutter;
    // 日期控件太长，需要换行显示
    &.date {
      flex-wrap: wrap;
    }
    & + .filter-row {
      margin-top: @row-gutter * 3;
    }
    .wrap-row {
      padding-left: @column-width + @row-gutter;
      padding-top: @row-gutter;
      // 80% 初始宽度使其一定换行
      flex: 1 0 80%;
      position: relative;
    }
    .wrap-row::before {
      content: "";
      width: 25px;
      height: 20px;
      position: absolute;
      left: @column-width - 20px;
      top: 5px;
      border-left: solid 1px #ebebeb;
      border-bottom: solid 1px #ebebeb;
      border-bottom-left-radius: 8px;
    }
    .wrap-row + .remove {
      padding-top: @row-gutter;
    }
  }
  .filter-column {
    width: @column-width;
    flex-shrink: 0;
    margin-right: @row-gutter;
  }
  .filter-operator {
    width: 100px;
    flex-shrink: 0;
    margin-right: @row-gutter;
  }
  .filter-value {
    flex: 1;
  }
  .remove {
    color: #bfbfbf;
    cursor: pointer;
    transition: color 0.2s;
    width: 25px;
    text-align: right;
    &:hover {
      color: #ff4d4f;
    }
  }
  .filter-bottom {
    display: flex;
    justify-content: center;
  }
  .filter-index {
    @size: 20px;
    width: @size;
    height: @size;
    border-radius: 100%;
    position: absolute;
    left: -2px;
    top: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    background: rgb(187, 207, 255);
  }
  .filter-index[data-index="0"] {
    background: rgb(85, 134, 255);
  }
  .filter-index[data-index="1"] {
    background: rgb(119, 158, 255);
  }
  .filter-index[data-index="2"] {
    background: rgb(153, 182, 255);
  }
}
</style>
