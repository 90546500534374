<template>
  <a-dropdown
    v-model="visible"
    :trigger="['contextmenu']"
    :get-popup-container="getPopupContainer"
  >
    <component
      :is="currentComponent.is"
      class="input-component"
      v-bind="$attrs"
      v-on="currentComponent.diffOn"
      :value="value"
      @focus="onFocus"
      @blur="visible = false"
      @contextmenu.capture.stop
    />
    <div
      slot="overlay"
      class="ant-dropdown-menu"
      :style="{ 'min-width': width + 'px' }"
    >
      <div class="ant-select-dropdown-menu">
        <VirtualList
          ref="input-select-virtuallist"
          :style="virtualListStyle"
          :data-key="'key'"
          :data-sources="filterValuesInner"
          :data-component="itemComponent"
          :item-class="classPrefix"
          :item-class-add="selectedClass"
          :extra-props="{ click: onClick }"
          :keeps="30"
        />
        <a-empty v-if="!filterValuesInner.length" :image="simpleImage" />
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import { Empty } from "ant-design-vue";
import VirtualList from "vue-virtual-scroll-list";
import SelectItem from "./select-item";

export default {
  name: "input-number-select",
  components: { VirtualList },
  props: {
    getPopupContainer: Function,
    value: [Number, String],
    filterValues: {
      type: Array,
      default: () => []
    },
    isNumber: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      width: 0,
      filterValuesInner: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      itemComponent: SelectItem,
      classPrefix: "ant-select-dropdown-menu-item"
    };
  },
  watch: {
    filterValues() {
      if (this.filterValues) {
        this.filterValuesInner = this.filterValues.map(item => {
          return {
            label: item.label || item.value,
            key: `${typeof item.value}-${item.value}`,
            ...item
          };
        });
        // 如果输入的值不在返回的搜索列表里, 就默认把输入的值填入下拉菜单
        if (!this.filterValues.length && this.value) {
          const value = this.value;
          this.filterValuesInner = [
            { label: value, key: `${typeof value}-${value}`, value }
          ];
        }
        const el = this.$refs["input-select-virtuallist"];
        if (el) el.reset();
      }
    }
  },
  computed: {
    currentComponent() {
      if (this.isNumber) {
        return {
          is: "a-input-number",
          diffOn: { change: this.onChange }
        };
      }
      return {
        is: "a-input",
        diffOn: { input: this.onInput }
      };
    },
    virtualListStyle() {
      if (this.filterValuesInner.length > 20) {
        return { height: "230px", overflow: "auto" };
      }
      return "";
    }
  },
  mounted() {
    this.$nextTick(this.getWidth);
  },
  methods: {
    // 让下拉菜单和输入框一个宽度
    getWidth() {
      const { offsetWidth } = this.$el;
      this.width = offsetWidth;
    },
    // 获取焦点之后不能立刻打开下拉菜单, 立刻打开的话可能会出现别的下拉菜单还没收起这个下拉菜单就展开了的情况~
    onFocus() {
      setTimeout(() => {
        this.visible = true;
      }, 300);
      this.$emit("search");
    },
    onInput(evt) {
      this.onChange(evt.target.value);
    },
    onChange(value) {
      this.$emit("change", value);
      this.$emit("search", value);
    },
    onClick(item) {
      this.$emit("change", item.value);
    },
    selectedClass(index) {
      const item = this.filterValuesInner[index];
      if (!item) return "";
      if (item.value == this.value) {
        return this.classPrefix + "-selected";
      }
      return "";
    }
  }
};
</script>

<style lang="less" scoped>
.input-component {
  width: 100%;
}
.ant-dropdown-menu {
  padding: 0;
}
</style>
